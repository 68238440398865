@import "../../constants.scss";

.generate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
  }
  .output {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    .outputButtons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 100%;
      position: relative;
      .changeOutputButtons {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 25px;
        right: 10px;
        .outputDivider {
          margin: 0 1px;
        }
        .previousOutputButton, .nextOutputButton {
          cursor: pointer;
        }
        $margin-lr: 3px;
        .previousOutputButton {
          margin-right: $margin-lr;
        }
        .nextOutputButton {
          margin-left: $margin-lr;
        }
      }
    }
  }
  .helperText {
    @media screen and (max-width: $sm) {
      max-width: 100%;
    }
    @media screen and (min-width: $sm1) {
      max-width: 50%;
    }
  }
}