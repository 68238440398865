@import "../../constants.scss";

.serviceWrapper {
  height: 100%;
}
.stepper {
  margin-bottom: 5rem;
  width: 100%;
}
.steps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  height: 100%;
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .input {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .helperText {
    @media screen and (max-width: $sm) {
      max-width: 100%;
    }
    @media screen and (min-width: $sm1) {
      max-width: 50%;
    }
  }
}
.stepBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stepButtons {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}