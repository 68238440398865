@import "../../constants.scss";

.serviceSelect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  margin-bottom: 1rem;
  height: 100%;
  .hero {
    text-align: center;
    padding: 50px 20px;
    color: #fff;
    padding-top: 0px;
    .description {
      font-size: 1.2rem;
      margin: 20px 0;
    }
  }
  .content {
    .contentTitle {
      text-align: center;
      color: #fff;
    }
    .services {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      // row-gap: 20px;
    }
  }
}