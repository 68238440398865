@import "../../constants.scss";

.textAreaStep {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    .title {
      margin-bottom: 15px;
    }
    .caption {
      margin: 0px;
      margin-bottom: 25px;
      .optional {
        margin: 0px;
      }
    }
  }
  .input {
    width: 100%;
  }
}