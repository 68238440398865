
$button-hw: 250px;

.buttonCard {
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 0px;
  background-color: transparent;
  height: $button-hw;
  width: $button-hw;
  display: flex;
  justify-content: center;
  align-items: center;
}