@import '@mantine/core/styles.css';
@import './constants.scss';

body {
  background: $bg1;
  // background: #4a90e2;
  color: #FFFFFF;
}

body, html, #root {
  // height: 100%;
  width: 100%;
  box-sizing: border-box;
}

html, #root {
  height: 100%;
}

.app {
  height: 100%;
  width: 100%;
}

.MuiMobileStepper-progress {
  background-color: $bg1 !important;
  span {
    background-color: $bg2 !important;
  }
}