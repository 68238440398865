@import "../../constants.scss";

.pipeline {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .logo {
    margin-right: 1px;
    height: 75px;
    width: 75px;
    text-decoration: none;
    cursor: pointer;
    .logoImg {
      height: 100%;
      width: 100%;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
    .stepper {
      margin-bottom: 5rem;
      width: 100%;
    }
    .steps {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: space-between;
      width: 100%;
      height: 80%;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        text-align: center;
      }
      .input {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
      }
      .helperText {
        @media screen and (max-width: $sm) {
          max-width: 100%;
        }
        @media screen and (min-width: $sm1) {
          max-width: 50%;
        }
      }
      .stepBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .stepButtons {
        display: flex;
        justify-content: space-around;
        gap: 1rem;
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  }
}