@import "../../constants.scss";

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 75%;
  .dropzone {
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 7px 0px 2px 0px;
    height: 100%;
    margin-bottom: 10px;
    .feedback {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
  .notes {
    font-size: 12px;
    color: #FFFFFF;
  }
  .control {
    width: 250px;
    left: calc(50% - 125px);
  }
}
