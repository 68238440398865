
.textAreaContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 10px;
  height: 100%;
  width: 100%;
  .label {
    font: 16px;
  }
  .textArea {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

.textAreaWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  .loadingContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


