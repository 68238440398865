$xs: 575px;
$xs1: 576px;
$sm: 767px;
$sm1: 768px;
$md: 991px;
$md1: 992px;
$lg: 1199px;
$lg1: 1200px;
$xl: 1599px;
$xl1: 1600px;

$red: #800020;
$gold: #B8860B;
$black: #000000;

$bg1: #003366;
$bg2: #4a90e2;