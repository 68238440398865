@import "../../constants.scss";

.error {
  text-align: center;
  padding: 50px 20px;
  color: #fff;
  padding-top: 0px;
  .errorDescription {
    font-size: 1.2rem;
    margin: 20px 0;
  }
}