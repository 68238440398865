@import "../../constants.scss";

$button-hw: 50px;
// $button-br: calc($button-hw / 2);
$button-br: 50px;
$chatbox-mb: 10px;
$button-space: $button-hw + $chatbox-mb;
$chatbox-br: 25px;
$open-h: 800px;
$open-w: 500px;
.chatbot {
  bottom: 50px;
  right: 50px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  &Open, &Closed {
    justify-content: flex-end;
    align-items: flex-end;
    position: fixed;
  }
  &Open {
    width: $open-w;
    height: $open-h;
  }
  &Closed {
    width: $button-hw;
    height: $button-hw;
  }
  &FullScreen {
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .toggleButton {
    background-color: $bg2;
    border: none;
    box-sizing: border-box;
    height: $button-hw;
    width: $button-hw;
    padding: 0px;
    cursor: pointer;
    border-radius: $button-br;
    display: flex;
    justify-content: center;
    align-items: center;
    .toggleButtonIcon {
      padding: 0px;
      height: 24px;
      width: 24px;
      transition: transform 0.3s;
      &Open {
        transform: rotate(-135deg);
      }
    }
  }
}

.chatbotBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid $bg2;
  background-color: $bg1;
  border-radius: $chatbox-br;
  box-sizing: border-box;
  padding: 10px;
  .sidebar {
    height: 100%;
    width: 10%;
    border: 1px solid $bg2;
    color: #FFFFFF;
    border-radius: $chatbox-br;
    margin-right: 10px;
    .sidebarContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      row-gap: 50px;
      height: 100%;
      box-sizing: border-box;
      padding: 30px 5px;
      .sidebarButton {
        position: relative;
        .popUpFieldFormControl {
          position: absolute;
          width: 500px;
          top: 50px;
          left: 50px;
          z-index: 3;
          .popUpTextFieldInput {
            // width: 100%;
            width: 500px;
            height: 100%;
            border: 1px solid $bg2;
            background-color: $bg1;
            color: #FFFFFF;
            border-radius: $chatbox-br;
          }
        }
      }
    }
  }

  .messageField {
    width: 100%;
    .messageFieldFormControl {
      width: 100%;
      .messageInput {
        width: 100%;
        height: 100%;
        border: 1px solid $bg2;
        color: #FFFFFF;
        border-radius: $chatbox-br;
      }
    }
  }
  .chatbotBody {
    box-sizing: border-box;
    // padding: 10px;
    width: 100%;
    // height: calc(100% - $button-space);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $chatbox-mb;
    .chatbotMessagesContainer {
      margin-bottom: 15px;
      width: 100%;
      height: 100%;
      overflow: auto;
      .chatbotMessages {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        row-gap: 10px;
        width: 100%;
        min-height: min-content;
        &PopUp {
          height: 100vh;
        }
        &FullScreen {
          height: 100%;
        }
        .message {
          display: flex;
          align-items: center;
          width: 100%;
          .messageBlock {
            border-radius: $chatbox-br;
            padding: 10px 15px;
            width: 90%;
            color: #FFFFFF;
          }
        }
        
        .systemMessage {
          justify-content: flex-start;
          .systemMessageBlock {
            background-color: $bg2;
            border: 1px solid $bg1;
          }
        }
        
        .userMessage {
          justify-content: flex-end;
          .userMessageBlock {
            background-color: $bg1;
            border: 1px solid $bg2;
          }
        }
  
      }
    }
  }
}

.sendMessageButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
  border: none;
  border-radius: $chatbox-br !important;
}

.popUpTextField {
  position: relative;
  height: 48px;
  width: 48px;
  .popUpIcon {
    height: 48px;
    width: 48px;
  }
  .popUpTextFieldInput {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 0px;
    right: -30px;
  }
}