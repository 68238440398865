
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin: 10px;
  .label {
    font: 16px;
  }
  .input {
    appearance: none;
    border: 1px solid #000000;
    min-height: 25px;
    width: 100%;
    border-radius: 5px;
  }
}
