@import "../../constants.scss";

$button-h: 100px;
$button-w: 325px;

.aCard {
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 0px;
  // background-color: transparent;
  height: $button-h;
  width: $button-w;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  background: $bg2;
  &:hover {
    // transform: translateY(-5px);
    background: $bg1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  .card {
    background-color: transparent;
    .cardContent {
      color: #FFFFFF;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
    }
  }
}